.main {
  width: 100wh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;

  
  @media screen and (min-width: 1024px) {
    height: 100vh;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
  }
}

.logoSection {
  padding: 8px 24px 24px; 

  @media screen and (min-width: 1024px) {
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 64px;
    width: 65%;
  }
}

.logoImage {
  width: 160px;
  height: 32px;
}

.formSection {
  grid-row: 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

.initialFormContainer {
  display: flex;
  padding: 64px 24px 24px;
  flex-direction: column;
  text-align: left;

  @media screen and (min-width: 1024px) {
    padding: 9vh 0 0;
    width: 65%;
  }

  h1 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: var(--color-text-primary);

    @media screen and (min-width: 1024px) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .subText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 32px;
    color: var(--color-text-secondary);

    @media screen and (min-width: 1024px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.promoImageSection {
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-dark);

  @media screen and (min-width: 1024px) {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.promoImageContainer {
  position: relative;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 65%;
  }
}

.promoImage {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 256px;

  @media screen and (min-width: 600px) {
    height: 360px;
  }
  
  @media screen and (min-width: 800px) {
    height: 400px;
  }

  @media screen and (min-width: 1024px) {
    height: auto;
    border-radius: 36px;
  }
}

.alreadyAppliedContainer {
  position: absolute;
  bottom: -48px;
  left: 24px;
  border: 1px solid var(--color-gray-200);

  @media screen and (min-width: 1024px) {
    border: none;
    bottom: -24px;
    left: -48px;
  }
}

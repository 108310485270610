:root {
  --color-primary: #6C2B8C;
  --color-primary-dark: #261230;
  --color-purple-100: #4b265e;
  --color-gray-100: #58585c;
  --color-gray-200: #bebec6;
  --color-transparent-50: #00000080;
  --color-text-primary: #262627;
  --color-text-secondary: #58585C;
}

@font-face {
  font-family: 'Faktum';
  src: url('/public/static/fonts/RBFaktumNeue-SemiBold.otf');
}

body {
  margin: 0;
  min-width: 400px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Faktum', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

label {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  input, select {
    margin-top: 4px;
  }
}

input {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px var(--color-gray-200);
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;

  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px var(--color-gray-200);
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 16px;;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 0;
    padding: 0;
  }

  .mainText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

    @media screen and (min-width: 1024px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .subText {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    @media screen and (min-width: 1024px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.container {
  display: inline-flex;
  background-color: white;
  padding: 12px;
  border-radius: 16px;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    padding: 16px;
  }
}

.container {
  display: flex;
  align-items: center;
  z-index: 0;

  img {
    width: 44px;
    height: 44px;
  
    &:not(:first-of-type) {
      margin-left: -16px;
    }
  }
}


.button {
  background: #6c2b8c;
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: var(--color-purple-100);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loaderContainer {
  position: absolute;
  left: -24px;
  height: 18px;
  width: 16px;
}

.loader {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: absolute;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  padding: 24px;
  padding-bottom: 36px;
  background-color: white;
  min-width: 400px;
  
  @media screen and (min-width: 1024px) {
    max-width: 600px;
    border-radius: 16px;
    bottom: unset;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-transparent-50);
}

.registrationForm {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  margin-top: 16px;

  p {
    color: var(--color-gray-100);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-align: justify;
  }

  a {
    color: var(--color-gray-100);
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
}

.closeButtonContainer {
  text-align: end;
}

.closeButton {
  border: none;
  background-color: white;
  padding: 4px;
  margin: 0;

  &:active {
    background-color: var(--color-gray-200);
  }
}

.formRow {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.submitButtonContainer {
  padding-top: 16px;
}

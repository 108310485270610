.main {
  width: 100wh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;

  
  @media screen and (min-width: 1024px) {
    height: 100vh;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
  }
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media screen and (min-width: 1024px) {
    margin: 0 auto;
    padding: 48px 0 64px;
    justify-content: flex-start;
    width: 65%;
  }
}

.logoImage {
  width: 160px;
  height: 32px;
}

.messageSection {
  grid-row: 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin-top: 88px;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

.messageContainer {
  display: flex;
  flex-direction: column;
  padding: 0 42px;
  transform: translateY(-65px);
  text-align: center;

  @media screen and (min-width: 1024px) {
    padding: 11vh 0 0;
    width: 65%;
    text-align: left;
  }

  svg {
    margin: 0 auto;
    border-radius: 50%;

    @media screen and (min-width: 1024px) {
      transform: none;
      margin: 0;
    }
  }

  h1 {
    padding: 0;
    margin: 0;
    margin-top: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: var(--color-text-primary);

    @media screen and (min-width: 1024px) {
      margin-top: 24px;
      font-size: 28px;
      line-height: 34px;
    }
  }

  .subText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 32px;
    color: var(--color-text-secondary);

    @media screen and (min-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.promoImageSection {
  grid-row: 2;

  @media screen and (min-width: 1024px) {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.promoImageContainer {
  position: relative;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 65%;
  }
}

.promoImage {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 256px;

  @media screen and (min-width: 600px) {
    height: 360px;
  }
  
  @media screen and (min-width: 800px) {
    height: 400px;
  }

  @media screen and (min-width: 1024px) {
    height: 100vh;
  }
}
